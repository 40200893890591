<template>
  <div>
    <loading v-if="pageLoading" />
    <div v-show="!pageLoading" class="container">
      <img class="bg" src="../../assets/images/mobile/home-bg.jpg" />
      <div class="content">
        <div class="colume-item">
          <img class="bg" src="../../assets/images/mobile/home-header-bg.png" />
          <div class="content header-item">
            <div class="logo-item">
              <img src="../../assets/logo-white.png" />
            </div>
            <img class="title" src="../../assets/images/mobile/home-title-01.png" />
            <img class="header-img" src="../../assets/images/mobile/home-header-img.png" />
            <div class="btn" @click="handleDownloadApp"><img src="../../assets/images/mobile/arrow-icon.png">搭讪</div>
          </div>
        </div>
        <div class="colume-item">
          <img class="title" src="../../assets/images/mobile/home-title-02.png" />
          <div class="banner-item">
            <carousel-3d
              :loop="true"
              :autoplay="false"
              :perspective="0"
              :display="3"
              :animationSpeed="800"
              :width="233"
              :height="307"
              :border="0"
            >
              <slide v-for="(item, index) in peopleArr" :index="index" :key="index">
                <div class="img-item">
                  <img :src="item" />
                </div>
              </slide>
            </carousel-3d>
          </div>
          <div class="btn" @click="handleDownloadApp"><img src="../../assets/images/mobile/arrow-icon.png">立即约会</div>
        </div>
        <div class="colume-item">
          <img class="title" src="../../assets/images/mobile/home-title-03.png" />
          <img class="yinsi-img" src="../../assets/images/mobile/home-img-01.png" />
          <div class="btn" @click="handleDownloadApp"><img src="../../assets/images/mobile/arrow-icon.png">查看私密照</div>
        </div>
        <div class="colume-item">
          <div class="tips">本平台为异性高端交友社区 未满18岁者请勿注册</div>
          <img class="footer-img" src="../../assets/images/mobile/home-footer-img.jpg" @click="handleDownloadApp" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Carousel3d, Slide } from 'vue-carousel-3d'
import { mapMutations } from 'vuex'
import { getSetting, gongCode, userInfo } from '@/api/index'
import Loading from '../../components/Loading.vue'
export default {
  name: 'Home',
  components: {
    Loading,
    Carousel3d,
    Slide
  },
  data () {
    return {
      pageLoading: true,
      system: '',
      upAppInfo: {},
      peopleArr: [
        require('../../assets/images/mobile/people-tag-01.jpg'),
        require('../../assets/images/mobile/people-tag-02.jpg'),
        require('../../assets/images/mobile/people-tag-03.jpg'),
      ]
    }
  },
  mounted() {
    const href = window.location.href
    if (href.indexOf('code') > -1 && href.indexOf('state=22138') > -1) {
      const query = window.location.search.split('?')[1].split('&')
      let code = ''
      let state = ''
      for (let index = 0; index < query.length; index++) {
        const item = query[index].split('=')
        if (item && item.length === 2 && item[0] === 'code') {
          code = item[1]
          continue
        }
        if (item && item.length === 2 && item[0] === 'state') {
          state = item[1]
          continue
        }
      }
      const params = {
        code,
        state
      }
      gongCode(params).then(res => {
        if (res.error === 0) {
          window.location.href = '/#/login?open_id=' + res.info
          // this.$router.replace({ path: '/login', query: { open_id: res.info} })
          this.pageLoading = false
        } 
        if (res.error === 33) {
          this.SET_TOKEN(res.info)
          this.handleGetUserInfo()
        }
      })
    } else {
      this.pageLoading = false
    }
    this.checkSystem()
    this.handleGetSetting()
    setTimeout(() => {
      document.getElementsByClassName('carousel-3d-container')[0].style.height = 'auto'
    }, 500);
  },
  methods: {
    ...mapMutations(['SET_TOKEN', 'SET_USERINFO']),
    handleGetUserInfo () {
      userInfo().then(res => {
        if (res.error === 0) {
          window.location.href = '/#/recharge'
          // this.$router.replace({ path: '/recharge' })
        } else {
          this.SET_TOKEN('')
          window.location.href = '/api/gongCode'
        }
        this.pageLoading = false
      })
    },
    checkSystem () {
      const ua = navigator.userAgent
      const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
      if (isiOS) {
        this.system = 'ios'
      } else if (isAndroid) {
        this.system = 'android'
      } else {
        this.system = 'pc'
      }
    },
    handleGetSetting () {
      getSetting({key: 'up_app'}).then(res => {
        try {
          this.upAppInfo = JSON.parse(res.info)
        } catch (error) {
          this.upAppInfo = res.info
        }
      })
    },
    handleDownloadApp () {
      const { upAppInfo, system } = this
      if (system === 'ios') {
        window.location.href = upAppInfo.ios_down_url
      }
      if (system === 'android') {
        window.location.href = upAppInfo.android_down_url
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content {
    position: relative;
    z-index: 1;
  }
  .btn {
    width: 710rem;
    height: 112rem;
    border-radius: 56rem;
    background-color: #fde101;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 36rem;
    font-weight: 600;
    margin-bottom: 110rem;
    img {
      width: 36rem;
      height: 36rem;
      margin-right: 10rem;
    }
  }
  .colume-item {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .title {
      width: 666rem;
    }
    .header-item {
      position: relative;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      .logo-item {
        width: 650rem;
        padding-top: 80rem;
        img {
          width: 240rem;
        }
      }
      .header-img {
        width: 662rem;
        margin-left: 30rem;
      }
    }
    .banner-item {
      margin: 72rem 0 96rem;
      width: 700rem;
      height: 614rem;
      /deep/.carousel-3d-container {
        margin: 0;
        height: 307px !important;
        // height: auto !important;
        .carousel-3d-slide {
          background: none;
        }
      }
      .img-item {
        width: 233px;
        height: 307px;
        border-radius: 18rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .yinsi-img {
      width: 710rem;
      margin: 80rem 0 98rem;
    }
    .tips {
      font-size: 24rem;
      color: rgba(255, 255, 255, .6);
      margin-bottom: 20rem;
    }
    .footer-img {
      width: 100%;
    }
  }
}
</style>
